@tailwind base;
@tailwind components;
@tailwind utilities;

.animate-head {
  transform-origin: center;
  animation: head 1s ease-in-out infinite alternate;
}

@keyframes head {
  0% {
    transform: rotate(-2deg);
  }

  100% {
    transform: rotate(2deg);
  }
}

.animate-rotate {
  transform-origin: center;
  animation: rotate 2s ease-in-out infinite alternate;
}

@keyframes rotate {
  0% {
    transform: rotate(-10deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.animate-rotate-2 {
  transform-origin: center;
  animation: rotate 2s ease-in-out infinite alternate;
}

@keyframes rotate {
  0% {
    transform: translate(1px, 10px) skewY(4deg);
  }

  100% {
    transform: translate(0px, 0px) skewY(0deg);
  }
}

.animate-pointer-1 {
  transform-origin: 428px 190px;
  animation: pointer-1 2s linear infinite;
}

@keyframes pointer-1 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.animate-arm {
  transform-origin: 343px 230px;
  animation: arm-1 0.4s ease infinite alternate;
}

@keyframes arm-1 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(4deg);
  }
}

.animate-arrow {
  animation: arrow-1 1s ease infinite alternate;
}

@keyframes arrow-1 {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(30px);
  }
}

.animate-blur {
  animation: blur 2s ease-in-out infinite alternate;
}

@keyframes blur {
  0% {
    filter: blur(50px);
  }

  100% {
    filter: blur(100px);
  }
}
